var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-full p-t140 bg-repeat",style:(_vm.getBackgroundImage('ptn-1.png'))},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12"},_vm._l((_vm.data.slice(1,2)),function(value,index){return _c('div',{key:index,staticClass:"overlay-wraper bg-no-repeat bg-cover latest-blog-dark-outer p-a20 m-b30",style:('background-image:url('+ _vm.imagePath(value.primaryImage) + ');')},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                                name: 'news-category-slug'
                                , params: {
                                    category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                                    , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                                }
                            })}},[_c('div',{staticClass:"overlay-main bg-black opacity-04"}),_vm._v(" "),_c('div',{staticClass:"latest-blog-dark text-uppercase p-a20"},[_c('h4',{staticClass:"m-a0"},[_c('span',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t(value.alternativeHeadline))+" ")])]),_vm._v(" "),_c('div',{staticClass:"v-date text-white font-weight-700"},[_vm._v(_vm._s(_vm.datePublished(value.lastReviewed)))])])])],1)}),0),_vm._v(" "),_c('div',{staticClass:"col-md-6 col-sm-12"},_vm._l((_vm.data.slice(2,3)),function(value,index){return _c('div',{key:index,staticClass:"overlay-wraper bg-no-repeat bg-cover latest-blog-dark-outer2 m-b30",style:('background-image:url('+ _vm.imagePathFull(value.secondaryImage) + ');')},[_c('div',{staticClass:"overlay-main bg-black opacity-04"}),_vm._v(" "),_c('div',{staticClass:"latest-blog-square text-white"},[_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                    name: 'news-category-slug'
                    , params: {
                        category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                        , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                    }
                })}},[_c('h4',{staticClass:"m-t0 m-b10"},[_c('span',{staticClass:"text-white font-30 text-uppercase"},[_vm._v(_vm._s(_vm.$t(value.alternativeHeadline)))])]),_vm._v(" "),_c('p',{staticClass:"font-weight-300"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.$t(value.articleResume),200)))]),_vm._v(" "),_c('span',{staticClass:"letter-spacing-4 font-12 text-white text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('plus d\'infos'))+" ")]),_vm._v(" "),_c('div',{staticClass:"v-date text-white font-weight-700 text-uppercase"},[_vm._v(_vm._s(_vm.datePublished(value.lastReviewed)))])])],1)])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }